import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import Pandits from '@sections/Pandits';
import Contact from '@sections/Contact';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';
import pic1 from '../images/art/whatsapp-logo.png'
import pic2 from '../images/art/call.png'

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <Pandits />
    <Faq />
    <div className="help">
	    <a href="https://api.whatsapp.com/send?phone=918197249957&text=Hey, I need some help." target="_blank" rel="noreferrer" className="whatsapp-icon">
	        <img src={pic1} alt="WhatsApp Icon"/>
	    </a>
	</div>
	<div className="phone">
	    <a href="tel:8197249957" title="NamasteGod Call" className="call-icon">
	        <img src={pic2} alt="Call Icon"/>
	    </a>
	</div>
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;
