import React from 'react';

import { Section, Container } from '@components/global';

const Team = () => (
      <Section id="contact" accent="secondary">
        <Container style={{ position: 'relative' }}>
          <h1 style={{ marginBottom: 40 }}>Contact</h1>
          <form name="Contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="Contact" />
              <div className="field half first">
                  <label htmlFor="name">Phone</label>
                  <input type="text" name="name" id="name" required="true"/>
              </div>
              <div className="field half">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" id="email" />
              </div>
              <div className="field">
                  <label htmlFor="message">Message</label>
                  <textarea name="message" id="message" rows="3"></textarea>
              </div>
              <ul className="actions">
                  <li><input type="submit" value="Send Message" className="special" /></li>
                  <li><input type="reset" value="Clear" /></li>
              </ul>
          </form>
        </Container>
      </Section>
);

export default Team;
