import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Accordion from '../common/Accordion.js'
import NGImg from '../../images/art/logo.png'

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const Pandits = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "Pandit Ashutosh Pandey" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "BiharPandit" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "UttarakhandPandit" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="pandits">
        <Container>
          <Grid>
            <div>
              <h2>Pandits from Varanasi & Uttar Pradesh</h2>
              <p>
                <ExternalLink class="no-highlight" href="https://en.wikipedia.org/wiki/Varanasi">Varanasi</ExternalLink> in Uttar Pradesh is well known for its rich heritage and culture when it comes to Hindu religion,
                as is located on the banks of the Ganga river. NamasteGod provides 15+ years of experienced pandits who hail from Varanasi & Uttar Pradesh region.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Bihari Pandits</h2>
              <p>
                <ExternalLink class="no-highlight" href="https://en.wikipedia.org/wiki/Bihari_culture">Bihar</ExternalLink> is prominent for its rich culture, as it is a combination of Mithila, Bhojpuri and Magadha cultures.
                NamasteGod provides 15+ years experienced pandits from different regions of Bihar like Patna, Jamui, Lakhisarai, Devgarh and others.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Uttarakhand Pandits</h2>
              <p>
                Uttarakhand is popular for its culture & traditions, of which Kumaoni and Garhwali are well known. Haridwar in Uttarakhand is one of the places where the historic <ExternalLink class="no-highlight" href="https://en.wikipedia.org/wiki/Kumbh_Mela">Khumbha Mela </ExternalLink>
                is held. NamasteGod provides experienced pandits from the Kumaoni and Garhwali regions who have experience of 15+ years in puja paath.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
          <div>
            <h2 class="red">About Us </h2>
            <p>
              <img src={NGImg} lt="NamasteGod Logo"/>  NamasteGod, helps you choose and connect with experienced <ExternalLink class="highlight" href="https://www.namastegod.com/findonline/hindu/north-indian-hindi-speaking-pandit-ji-for-puja-in-bangalore-india-for-marriage-wedding-vedic-hindu-kumaoni-kashmiri-priests">North Indian pandits </ExternalLink>who have settled in Bangalore after their degree in Shastri and Jyotish Acharya.
              We have performed than 1000 pujas and few of our satisfied customers have left <ExternalLink class="highlight" href="https://g.co/kgs/MciV3q">reviews</ExternalLink> on us. <ExternalLink class="highlight red" href="#contact">Book </ExternalLink>your Pandit today!!
              
              <div typeof="schema:Product" class="no-show">
                <div rel="schema:review">
                  <div typeof="schema:Review">
                    <div rel="schema:reviewRating">
                      <div typeof="schema:Rating">
                        <div property="schema:ratingValue" content="4"></div>
                        <div property="schema:bestRating" content="5"></div>
                      </div>
                    </div>
                    <div rel="schema:author">
                      <div typeof="schema:Person">
                        <div property="schema:name" content="NamasteGod"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div property="schema:name" content="North Indian Pandit in Bangalore"></div>
                <div property="schema:description" content="We offer region specific pandit services such as Hindi Pandit in Bangalore, UP Pandit in Bangalore, Bihari Pandit in Bangalore, Kumaoni Pandit in Bangalore, Uttarakhand Pandit in Bangalore & Garhwali Pandit in Bangalore for all Pujas."></div>
                <div rel="schema:aggregateRating">
                  <div typeof="schema:AggregateRating">
                    <div property="schema:reviewCount" content="139"></div>
                    <div property="schema:ratingValue" content="4.8"></div>
                  </div>
                </div>
              </div>

            </p>
          </div>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 96px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default Pandits;
