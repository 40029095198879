import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';

const FAQS = [
  {
    title: 'Should the customer arrange for Puja Samagri or would the Panditji arrange ?',
    content: () => (
      <>
        Customers have the flexibility to book the Puja 
        along with Samagri or book only Panditji for the Puja.
      </>
    ),
  },
  {
    title: 'Do multiple Pandits call the customer at once upon enquiry ?',
    content: () => (
      <>
        No. Our team after understanding the customer's requirement will arrange a 
        qualified Panditji for the call. In case the customer not satisfied with that Panditji, 
        our team will connect you with another Panditji.
      </>
    ),
  },
  {
    title: 'Is there an option to perform Puja through Video Conferencing ?',
    content: () => (
      <>
        Yes. We can arrange for an onlie puja through Video Conferencing. During these days of COVID-19(Corona Virus), Video Conferencing is a safe option. 
        You can maintain social distancing and perform puja from the comfort of your own home.
      </>
    ),
  },
  {
    title: 'How will Pandit interact with us ? Is it difficult to perform puja online ?',
    content: () => (
      <>
        Our pandits will guide you on arranging the Puja Samagri, chanting of mantras and explaining 
        all the rituals involved in the puja as a part of the vidi, vidhan. 
        All of our pandits are well equiped to perform the puja through video conferencing. 
        The Puja would be performed through video conferencing apps like WhatsApp and Skype.
      </>
    ),
  },
  {
    title: 'What are the payment options available ? Cash or Wallet or Netbanking ?',
    content: () => (
      <>
        Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay, PhonePe) or e-Wallet (PayTM).
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq" accent>
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
